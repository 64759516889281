<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Banner Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="form.marketing_banner_id" disabled label="ID"></v-text-field>
                                <v-text-field outlined v-model="form.mb_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.mb_name" label="Name"></v-text-field>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>

                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-row>
                    <v-col cols=12 md=8>
                        <v-card-title class="headline">Image</v-card-title>
                        <v-card-subtitle>{{ form.image_file_name}}</v-card-subtitle>
                    </v-col>
                    <v-col cols=12 md=4>
                        <v-card-actions class="justify-end">
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.image_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                        </v-card-actions>                    
                    </v-col>
                    </v-row>
                    <div class="d-flex flex-no-wrap">
                        <v-img max-height="500" max-width="500" v-if="form.image_file_name" :src="this.form.file_url + this.form.image_file_name"></v-img>
                        <v-img max-height="500" max-width="500" v-else src="../../assets/image/no-image.png"></v-img>
                    </div>                
                </v-card>
                <div class="mt-3">
                    <v-btn color="cbtnSave" dark @click="save"><v-icon left dark>save</v-icon>Save</v-btn>        
                </div>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
import ApMarketingService from '@/services/ApMarketingService'
import UploadFiles from "@/components/UploadFiles";
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Marketing',disabled: false,href:'#',},
              {text:'Banner',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            status_list:[],
           
            form:[],
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'banners',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",

    }
  },
  async created() {
      this.form.marketing_banner_id = this.$route.params.marketing_banner_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){

        let title = 'Marketing Banner';
        this.form = {login_id: "",marketing_banner_id: this.form.marketing_banner_id}
        try{
            ApMarketingService.view_marketing_banner(this.form).then((res) => {
                this.form = res.data.data;
                this.status_list = res.data.statusList;                 
            }).catch((e)=>{
                    //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        }         
    },
    async save(){
        this.form.login_id = ""
        let title = 'Update Banner';
        try{
            ApMarketingService.update_marketing_banner(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success');           
            }).catch((e)=>{
                    //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        }     

    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        //console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'banners';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;
        

        if(selectedImage.imageFileName && selectedImage.section == ""){
            this.form.image_file_name = selectedImage.imageFileName
            this.form.file_url = selectedImage.fileUrl
            return
        }

        // this.form[selectedImage.section] = selectedImage.imageFileName;
        // this.form.file_url = selectedImage.fileUrl;
        
        // if(selectedImage.imageFileName && selectedImage.section =="items"){
        //     let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
        //     let totalImage = this.ciImages.length;
        //     let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
        //     this.ciImages.push(ciImageNew)
        //     this.saveIndicator = "(*)"
        //     //console.log(ciImageNew);

        // }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },    
  }
}
</script>